import React from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import TitleBar from "../../components/TitleBar";

const categories = [
  {
    name: "Main Category",
    description: "..testing",
    slug: "main-category",
    counts: 10,
  },
  {
    name: "--Sub Category",
    description: "..sub -testing",
    slug: "main-category/sub-category",
    counts: 20,
  },
];

function Categories() {
  return (
    <div>
      <TitleBar title="Categories" />
      <div className="mt-4"></div>

      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-2">
          <div className="px-4 sm:px-4 lg:px-4 py-4 bg-white shadow-md rounded-lg overflow-hidden">
            <h3 className="font-medium text-lg">New Category</h3>
            <form>
              <div className="space-y-6">
                <div className="">
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-themeOrange sm:max-w-md">
                          <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                          <input
                            id="username"
                            name="username"
                            type="text"
                            placeholder=""
                            autoComplete="username"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Cover photo
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <PhotoIcon
                            aria-hidden="true"
                            className="mx-auto h-12 w-12 text-gray-300"
                          />
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-themeOrange focus-within:outline-none focus-within:ring-2 focus-within:ring-themeOrange focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Description
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-themeOrange sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Country
                      </label>
                      <div className="mt-2">
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-themeOrange sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="submit"
                  className="text-sm font-medium text-white bg-themeOrange rounded-md px-4 py-1"
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-span-3">
          <div className="px-4 sm:px-4 lg:px-4 bg-white shadow-md rounded-lg overflow-hidden">
            <div className="sm:flex sm:items-center py-2">
              <div className="sm:flex-auto">
                <h3 className="font-medium text-lg">All Categories</h3>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-scroll shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full divide-y divide-gray-100 overflow-scroll">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-themeSemiGray sm:pl-6 lg:pl-8"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                          >
                            Slug
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-themeSemiGray"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100 bg-white">
                        {categories.map((category) => (
                          <tr key={category.username}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-themeDark sm:pl-6 lg:pl-8">
                              {category.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                              {category.description}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-themeDark font-light">
                              {category.slug}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-primaryBlue font-semibold ">
                              Edit
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
