import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import bannersReducer from './slices/bannersSlice';
import notificationReducer from './slices/notificationSlice'; 

const store = configureStore({
  reducer: {
    auth: authReducer,
    banners: bannersReducer,
    notification: notificationReducer, 
  },
});

export default store;
